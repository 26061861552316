var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-dialog',{attrs:{"title":"三级考点","visible":_vm.dialogVisible,"close-on-click-modal":false,"append-to-body":true,"width":"1200rem","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('el-table',{ref:"theTable",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"height":"520rem","default-expand-all":"","tree-props":{children: 'child'},"row-key":"knowledgeId"},on:{"row-click":_vm.getOpenDetail}},[_c('el-table-column',{attrs:{"prop":"text","align":"left","width":"400rem","label":"考点"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"checkpoint"},[_vm._v(" "+_vm._s(((row.knowledgeDesc) + "(" + (row.level) + "级考点)"))+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"rate","align":"left","label":" 得分率"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"checkpoint"},[_vm._v(" "+_vm._s(row.rate)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"do_question_count","align":"left","label":" 训练次数"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"checkpoint"},[_vm._v(" "+_vm._s(row.doQuestionCount)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"text","align":"left","label":"易错指数"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"checkpoint"},[_vm._v(" "+_vm._s(row.errorProne)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(row.level == 3),expression:"row.level == 3"}],staticClass:"btn",on:{"click":function($event){return _vm.doPaper(row)}}},[_vm._v(" 开始训练 ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }